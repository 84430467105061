/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useEffect } from 'react';
import useSWR from 'swr';
import { useHistory } from 'react-router-dom';
import Modal from '../../Components/Modal';
import SelectBox from '../../Components/SelectBox';
import { CustomerObject } from '../../../types/CustomerObject';
import { AssumedRoleObject } from '../../../types/UserObject';
import { AssumeRoleModalProps } from '../../../types/AssumeRoleModalProps';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import { useAppContext } from '../../../utils/AppContext';

import { getRequest } from '../../../utils/fetch';
import Utils from '../../../utils/Utils';

function AssumeRoleModal(props: AssumeRoleModalProps): JSX.Element {
  const { modalOpen } = props;
  const { addNotification } = useAppContext();
  const selectBoxWidth = 350;

  const history = useHistory();

  const { data: customerResp } = useSWR<Map<string, CustomerObject>>(['/organizations', 'UsersPage'], getRequest, { compare: () => false });
  const allCustomers = useMemo(() => {
    const tmpMap = new Map();
    customerResp?.forEach((customer) => {
      tmpMap.set(customer.orgid, customer);
    });
    return tmpMap;
  }, [customerResp]);

  const [rolesFilter, setRolesFilter] = useState([{ title: 'None', key: '0' }]);

  const [assumedRole, setAssumedRole] = useState<AssumedRoleObject>({
    partner: { title: '', key: '' },
    account: { title: '', key: '' },
    role: { title: '', key: '' },
  });

  useEffect(() => {
    if (assumedRole.partner.key !== '' && assumedRole.account.key === '') {
      setRolesFilter(Utils.getUserRolesByType('partner'));
    } else if ((assumedRole.account.key !== '')) {
      setRolesFilter(Utils.getUserRolesByType('account'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assumedRole.partner,
    assumedRole.account,
  ]);

  const partnersList = useMemo(() =>
    ((allCustomers && allCustomers.size > 0)
      ? Array.from(allCustomers.values()).filter((customer: CustomerObject) =>
        customer.type === 'PARTNER').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      : []
    ), [allCustomers]);

  const customersList = useMemo(() =>
    (allCustomers && allCustomers.size > 0 && assumedRole.partner.title !== ''
      ? Array.from(allCustomers.values()).filter((customer: CustomerObject) =>
        customer.type !== 'PARTNER' && customer.po === assumedRole.partner.key).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      : []
    ), [allCustomers, assumedRole.partner]);

  const setPartner = (newName?: SelectBoxItemType) => {
    const partner = newName !== undefined ? newName : assumedRole.partner;
    setAssumedRole((oldValues) => ({
      ...oldValues,
      partner,
      account: { title: '', key: '' },
      role: { title: '', key: '' },
    }));
  };
  const setAccount = (newName?: SelectBoxItemType) => {
    const account = newName !== undefined ? newName : assumedRole.account;
    setAssumedRole((oldValues) => ({
      ...oldValues,
      account,
      role: { title: '', key: '' },
    }));
  };
  const setRole = (newName?: SelectBoxItemType) => {
    const role = newName !== undefined ? newName : assumedRole.role;
    setAssumedRole((oldValues) => ({
      ...oldValues,
      role,
    }));
  };

  return (
    <Modal
      title="Assume Account and Role"
      width="500"
      secondaryButtonLabel="Cancel"
      secondaryButtonAction={() => modalOpen(false)}
      primaryButtonLabel="Submit"
      primaryButtonAction={() => {
        if ((assumedRole.partner.key !== '' || assumedRole.account.key !== '') && (assumedRole.role.key !== '')) {
          Utils.setAssumedRole(assumedRole);
          modalOpen(false);

          // do a redirect to LoginCode route
          history.push('/code');
        } else {
          addNotification({ type: 'info', message: 'Please select Partner/ Account & Role to proceed' });
        }
      }}
      setModalOpen={modalOpen}
      className="changepassword assumerole"
    >
      <div>
        { /* }
        <Searchbox
          type="light"
          list={filteredPartnerList || []}
          title="Select partner"
          icon={<ArrowIcon className="search__icon" />}
          alwaysShowIcon
          onChange={(event: ChangeEvent<HTMLInputElement>) => handlePartnerListChange(event.target.value)}
          searchValue={searchPartner}
          listWidth={listWidth}
          listRowHeight={listRowHeight}
          listRowRenderer={partnerRowRenderer}
          rowNumber={searchBoxMaxItems}
          iconClickToggleList
          className="search-partner"
          listStyle={{ position: 'absolute' }}
        />
        { */ }

        <SelectBox
          label="Partner"
          onClick={(item: SelectBoxItemType) => setPartner(item)}
          selectedItemKey={assumedRole.partner.key || ''}
          list={Utils.arrayToSelectbox(partnersList || [], 'orgid', 'name')}
          title={assumedRole.partner.title || 'Select'}
          type="light"
          isRequired
          listWidth={selectBoxWidth}
        />

        <SelectBox
          label="Account"
          onClick={(item: SelectBoxItemType) => setAccount(item)}
          selectedItemKey={assumedRole.account.key || ''}
          list={Utils.arrayToSelectbox(customersList || [], 'orgid', 'name')}
          title={assumedRole.account.title || 'Select'}
          type="light"
          listWidth={selectBoxWidth}
        />

        <SelectBox
          label="Role"
          onClick={(item: SelectBoxItemType) => setRole(item)}
          selectedItemKey={assumedRole.role.key || ''}
          list={Utils.arrayToSelectbox(rolesFilter || [], 'key', 'title')}
          title={assumedRole.role.title || 'Select'}
          isRequired
          type="light"
          listWidth={selectBoxWidth}
        />

      </div>
    </Modal>
  );
}

export default AssumeRoleModal;
