/* eslint-disable */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Loading from '../../Common/Components/Loading';
import { DataType } from '../../types/login';
import { BASEPATH, defaultIdleTimeoutSeconds, httpNotFound, sessionExpirationCookieName } from '../../utils/constants';
import Utils from '../../utils/Utils';
import { getRequest, getURL } from '../../utils/fetch';

class LoginCode extends Component<RouteComponentProps, unknown> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.onLoginSuccess = this.onLoginSuccess.bind(this);
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const code = query.get('code') || '';
    const scope = query.get('scope') || '';
    const state = query.get('state') || '';

    if (code !== '') {
      const url = getURL(`/tokens?code=${code}&client_id=${window.NSN.loginappClientId}&redirect_uri=${window.location.origin}${BASEPATH}/code&scope=${scope}&state=${state}`);
      fetch(url, {
        method: 'GET',
        credentials: 'include',
      }).then((res) => {
        localStorage.setItem(Utils.csrfTokenName, res.headers.get(Utils.csrfTokenName) || '');
        localStorage.setItem(Utils.JSONWebTokenName, res.headers.get('vsc-oauth-id-token') || '');
        return res.json();
      }).then((data) => this.onLoginSuccess(data))
        .catch(LoginCode.onLoginError);
    } else {
      if (Utils.isAssumingRole()) {
        this.onLoginSuccess({error: '', status: 0, name: '', user: { orgs:[], sites: [], orgNames: [], siteNames: []}});
      }
    }
  }

  onLoginSuccess(data: DataType) {
    // If login error, show error message
    if (data.error || data.status === httpNotFound) {
      return;
    }

    Utils.setCookie(sessionExpirationCookieName, (data.sessionExpiration || (Math.floor(Date.now() / 1000) + 3600)).toString(), defaultIdleTimeoutSeconds);

    getRequest('/organizations/users/user_configuration')
      .then((response) => {
        window.NSN = {
          ...window.NSN,
          userInfo: {
            authorization: response.permissions.map((perm) => (
              { allowed: perm.allowed,
                model: perm.model,
                type: response.userDetails.role.toUpperCase(),
              }
            )),
            name: response.userDetails.name.replace(/([a-z])([A-Z])/g, '$1 $2'),
            orgName: response.userDetails.name,
            id: response.userDetails.userid,
            login: {
              email: response.userDetails.email,
              userid: response.userDetails.userid,
            },
            user: {
              email: response.userDetails.email,
              name: response.userDetails.name.replace(/([a-z])([A-Z])/g, '$1 $2'),
              orgName: response.userDetails.name,
              userid: response.userDetails.userid,
              orgid: response.userDetails.orgid,
              orgs: [],
              orgNames: [],
              orgDefaultSite: {},
              orghierarchy: [],
              sites: [],
              siteNames: [],
              hierarchy: [],
            },
          },
        };

        const orgID = response.userDetails.orgid;

        getRequest(`/organizations`)
          .then((response2) => {
            response2.forEach((org) => {
              window.NSN.userInfo.user.orgs.push(org.orgid);
              window.NSN.userInfo.user.orgNames.push(org.name);
              window.NSN.userInfo.user.orghierarchy.push({
                customer: org.orgid,
                provider: org.po || '',
              });

              if (org.orgid === orgID) {
                window.NSN.userInfo.user.orgName = org.name;
              }
            });

            getRequest('/sites')
            .then((response3) => {
              response3.forEach((site) => {
                window.NSN.userInfo.user.sites.push(site.siteid);
                window.NSN.userInfo.user.siteNames.push(site.name);
                window.NSN.userInfo.user.hierarchy.push({
                  siteid: site.siteid,
                  orgid: site.orgid || '',
                });
                if (site.defaultSite) {
                  if (window.NSN.userInfo.user.orgDefaultSite[site.orgid] === undefined) {
                    window.NSN.userInfo.user.orgDefaultSite[site.orgid] = site.siteid;
                    };
                  };
                });

              // Clear session storage and set `userInfo` on storage
              sessionStorage.clear();
              sessionStorage.setItem('userInfo', JSON.stringify(window.NSN.userInfo));


              if (window.NSN.userInfo.user.orgs.length !== 1) {
                this.setBrowserTitleAndRedirect('Intelligent Lighting', '/sites');
                return;
              }

              // Set customerID to session storage
              sessionStorage.setItem('customerID', window.NSN.userInfo.user.orgs[0]);
              sessionStorage.setItem('customerName', window.NSN.userInfo.user.orgNames[0]);

              // Redirect if 'not one site'
              if (window.NSN.userInfo.user.sites.length !== 1) {
                this.setBrowserTitleAndRedirect('Intelligent Lighting', '/sites');
                return;
              }

              // Updated session storage
              sessionStorage.setItem('siteID', window.NSN.userInfo.user.sites[0]);
              sessionStorage.setItem('siteName', window.NSN.userInfo.user.siteNames[0]);

              // Redirect
              this.setBrowserTitleAndRedirect('Intelligent Lighting', '/sites');
          });
      });
    });
  };

  static onLoginError(xhr: XMLHttpRequest) {
    const errorText = 'Login failed';
    let errorDescription = 'Please contact the administrators.';
    const responseJSON = JSON.parse(xhr.responseText);

    if (responseJSON && responseJSON.message) {
      errorDescription = `${responseJSON.message}. ${errorDescription}`;
    }

    window.location.href = `${window.NSN.loginappURL}/error?error=${errorText}&error_description=${errorDescription}`;
  }

  setBrowserTitleAndRedirect(title: string, newPathName: string) {
    window.NSN.link = newPathName;

    if (window && window.document) {
      window.document.title = title;
    }

    this.props.history.push(newPathName);
  }

  render() {
    return (<Loading fullScreen isLoading />);
  }
}

export default withRouter(LoginCode);
